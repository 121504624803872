import React from 'react';
import {graphql} from 'gatsby';
import tw, {styled} from 'twin.macro';
import axios from "axios";

import Layout from '@/components/layout';
import Hero from '@/components/pages/other/hero';
import Nav from "@/components/nav";
import Features from "@/components/features";
import Faq from "@/components/faq";
import Footer from "@/components/footer";
import Email from "@/components/illustrations/email";
import {apiUrl} from "@/api";
import Seo from "@/components/seo";

const Wrapper = styled.div``;

const Subheader = styled.div`
	${tw`
	text-3xl
	font-extrabold
	text-primary-background
	sm:text-4xl
	lg:text-5xl
	text-center
	`}
`;

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
	data: any;
	path: string;
}

const Contact = (props: Props) => {
	const [sent, setSent] = React.useState(false);
	const [email, setEmail] = React.useState("");
	const [message, setMessage] = React.useState("");

	const sendContactMEssage = () => {
		axios.post(
			apiUrl("contact"), JSON.stringify({email, message})
		).then((response) => {
			setSent(true);
			setEmail("");
			setMessage("");
		}).catch(console.error);
	};

	return (
		<Layout>
			<Seo {...props}/>

			<Wrapper>
				<div className="bg-primary-background">
					<Nav/>
					<Hero
						title={"Contact us"}
						subtitle={"Leave us a message and we promise to respond within the shortest time possible"}
						indicator={"/support"}
						illustration={<Email
							height={"100%"}
							width={"100%"}
						/>}
					/>
				</div>

				<div className="bg-bg-background">
					<div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
						<div className="py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
							<div className="relative max-w-xl mx-auto">
								<svg className="absolute left-full transform translate-x-1/2" width="404"
									height="404" fill="none" viewBox="0 0 404 404">
									<defs>
										<pattern id="85737c0e-0916-41d7-917f-596dc7edfa27" x="0" y="0"
											    width="20"
											    height="20" patternUnits="userSpaceOnUse">
											<rect x="0" y="0" width="4" height="4" className="text-gray-200"
												 fill="currentColor"/>
										</pattern>
									</defs>
									<rect width="404" height="404"
										 fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"/>
								</svg>
								<svg className="absolute right-full bottom-0 transform -translate-x-1/2"
									width="404"
									height="404" fill="none" viewBox="0 0 404 404">
									<defs>
										<pattern id="85737c0e-0916-41d7-917f-596dc7edfa27" x="0" y="0"
											    width="20"
											    height="20" patternUnits="userSpaceOnUse">
											<rect x="0" y="0" width="4" height="4" className="text-gray-200"
												 fill="currentColor"/>
										</pattern>
									</defs>
									<rect width="404" height="404"
										 fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"/>
								</svg>

								{sent && (
									<div>
										<Subheader>Thank you!</Subheader>
										<p className="mt-4 text-xl text-center text-gray-600">
											We received your message. Please wait for a reply.
										</p>
									</div>
								)}

								{!sent && (
									<div className="mt-12">
										<div className="sm:col-span-2">
											<label htmlFor="email"
												  className="block text-sm font-medium leading-5 text-gray-700">Email</label>
											<div className="mt-1 relative rounded-md shadow-sm">
												<input
													value={email}
													type="email"
													name={"email"}
													onChange={event => setEmail(event.target.value)}
													className="form-input py-3 px-4 block w-full transition ease-in-out duration-150"
												/>
											</div>
										</div>
										<div className="sm:col-span-2 mt-6">
											<label htmlFor="message"
												  className="block text-sm font-medium leading-5 text-gray-700">Message</label>
											<div className="mt-1 relative rounded-md shadow-sm">
											<textarea
												value={message}
												name="message" rows={4}
												onChange={event => setMessage(event.target.value)}
												className="form-textarea py-3 px-4 block w-full transition ease-in-out duration-150"
											/>
											</div>
										</div>
										<div className="sm:col-span-2 mt-6">
											<span className="w-full inline-flex rounded-md shadow-sm">
												<button
													onClick={event => sendContactMEssage()}
													className="w-full flex items-center justify-center px-5 py-4 border border-transparent text-xl leading-6 font-extrabold rounded-md text-primary-text bg-primary-800 hover:bg-primary-900 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
												>
													Send message
												</button>
											</span>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>

				<div className="max-w-2xl lg:py-24 py-12 mx-auto pb-16 mt-12 sm:pb-0 sm:px-6 lg:max-w-screen-xl">
					<div className="max-w-4xl mx-auto text-center">
						<h2 className="text-3xl leading-9 font-extrabold text-primary-background">
							Unlimited users and zero restrictions
						</h2>
						<p className="mt-4 text-lg leading-7 text-gray-500">
							Your VM is like any other server and it can be upgraded to fit any scenario.
							We install only open source software which has no limitations in place, it is
							upgradable and highly customizable
						</p>
					</div>
					<Features/>
				</div>
			</Wrapper>

			<Wrapper>
				<div className="bg-bg-background mt-20">
					<div className="max-w-2xl mx-auto py-16 sm:py-24 sm:px-6 lg:max-w-screen-xl lg:px-8">
						<Faq/>
					</div>
				</div>

				<Footer/>
			</Wrapper>
		</Layout>
	);
};

export default Contact;
